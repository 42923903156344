import * as React from 'react';
import { ModalColorScheme } from 'components/modal/modal.config';

type ModalContextType = {
    modalOpen?: boolean;
    setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    modalColor: ModalColorScheme;
    setModalColor?: React.Dispatch<React.SetStateAction<ModalColorScheme>>;
    modalContent?: React.ReactNode;
    setModalContent?: any;
    onModalClose?: () => void;
    setModalClose?: any;
    scrollY?: number;
    setScrollY?: React.Dispatch<React.SetStateAction<number>>;
};

export const modalInitState = {
    modalOpen: false,
    modalColor: ModalColorScheme.None,
    modalContent: undefined,
    onModalClose: undefined,
    scrollY: 0,
};

const ModalContext = React.createContext<ModalContextType>(modalInitState);

export const Provider: React.FC = (props) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalColor, setModalColor] = React.useState<ModalColorScheme>(ModalColorScheme.None);
    const [modalContent, setModalContent] = React.useState(undefined);
    const [onModalClose, setModalClose] = React.useState(undefined);
    const [scrollY, setScrollY] = React.useState(0);

    return (
        <ModalContext.Provider
            value={{
                modalOpen,
                setModalOpen,
                modalColor,
                setModalColor,
                modalContent,
                setModalContent,
                onModalClose,
                setModalClose,
                scrollY,
                setScrollY,
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
};
export const Consumer = ModalContext.Consumer;

export default ModalContext;

/* eslint-disable */
export default {
      "share@NATIVE_SHARING_MW_LANDING_PAGE/ACTION": 0,
      "settings@cookies.footer.cookie_policy": 1,
      "onboarding@bumble.incognito.dismiss_warning.cta.cancel": 2,
      "signin@PHONE_INCORRECT": 3,
      "corp@copyright_notice": 4,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER": 5,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP": 6,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.HOW_TO": 7,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE": 8,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.SUBMIT_STORY": 9,
      "bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS": 10,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BFF": 11,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ": 12,
      "bumblesite@LAYOUT.FOOTER.BUMBLE_DATE": 13,
      "bumblesite@LAYOUT.FOOTER.CAREERS": 14,
      "bumblesite@LAYOUT.FOOTER.COMPANY": 15,
      "bumblesite@LAYOUT.FOOTER.EVENTS": 16,
      "bumblesite@LAYOUT.FOOTER.GUIDELINES": 17,
      "bumblesite@LAYOUT.FOOTER.HELP_CENTER": 18,
      "bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES": 19,
      "bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY": 20,
      "bumblesite@LAYOUT.FOOTER.THEAPP": 21,
      "bumblesite@LAYOUT.FOOTER.TNC": 22,
      "bumblesite@LAYOUT.HEADER.ABOUT": 23,
      "bumblesite@LAYOUT.HEADER.AMBASSADORS": 24,
      "bumblesite@LAYOUT.HEADER.BEEHIVE": 25,
      "bumblesite@LAYOUT.HEADER.THE_SHOP": 26,
      "bumblesite@team_bumble.ceo.full_name": 27,
      "bumblesite@team_bumble.page_about.badoo_app_block.subtitle": 28,
      "bumblesite@team_bumble.page_about.badoo_app_block.text": 29,
      "bumblesite@team_bumble.page_about.badoo_app_block.title": 30,
      "bumblesite@team_bumble.page_about.bumble_app_block.subtitle": 31,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.first": 32,
      "bumblesite@team_bumble.page_about.bumble_app_block.text.second": 33,
      "bumblesite@team_bumble.page_about.bumble_app_block.title": 34,
      "bumblesite@team_bumble.page_about.hero_block.title.first": 35,
      "bumblesite@team_bumble.page_about.hero_block.title.second": 36,
      "bumblesite@team_bumble.page_about.real_connections_block.title": 37,
      "bumblesite@team_bumble.page_austin.cap_metro.text": 38,
      "bumblesite@team_bumble.page_austin.cap_metro.title": 39,
      "bumblesite@team_bumble.page_austin.celebrations.text": 40,
      "bumblesite@team_bumble.page_austin.celebrations.title": 41,
      "bumblesite@team_bumble.page_austin.community_block.title": 42,
      "bumblesite@team_bumble.page_austin.dental.text": 43,
      "bumblesite@team_bumble.page_austin.dental.title": 44,
      "bumblesite@team_bumble.page_austin.disability_insurance.text": 45,
      "bumblesite@team_bumble.page_austin.disability_insurance.title": 46,
      "bumblesite@team_bumble.page_austin.eap.text": 47,
      "bumblesite@team_bumble.page_austin.eap.title": 48,
      "bumblesite@team_bumble.page_austin.eip.text": 49,
      "bumblesite@team_bumble.page_austin.eip.title": 50,
      "bumblesite@team_bumble.page_austin.family_care.text": 51,
      "bumblesite@team_bumble.page_austin.family_care.title": 52,
      "bumblesite@team_bumble.page_austin.family_planning.text": 53,
      "bumblesite@team_bumble.page_austin.family_planning.title": 54,
      "bumblesite@team_bumble.page_austin.financial.title": 55,
      "bumblesite@team_bumble.page_austin.food.text": 56,
      "bumblesite@team_bumble.page_austin.food.title": 57,
      "bumblesite@team_bumble.page_austin.hackathon.text": 58,
      "bumblesite@team_bumble.page_austin.hackathon.title": 59,
      "bumblesite@team_bumble.page_austin.happiness_block.title": 60,
      "bumblesite@team_bumble.page_austin.health_savings.text": 61,
      "bumblesite@team_bumble.page_austin.health_savings.title": 62,
      "bumblesite@team_bumble.page_austin.health_wellbeing_block.title": 63,
      "bumblesite@team_bumble.page_austin.hospital.text": 64,
      "bumblesite@team_bumble.page_austin.hospital.title": 65,
      "bumblesite@team_bumble.page_austin.insurance.text": 66,
      "bumblesite@team_bumble.page_austin.insurance.title": 67,
      "bumblesite@team_bumble.page_austin.internal_talks.text": 68,
      "bumblesite@team_bumble.page_austin.internal_talks.title": 69,
      "bumblesite@team_bumble.page_austin.learning_block.title": 70,
      "bumblesite@team_bumble.page_austin.learning_budget.text": 71,
      "bumblesite@team_bumble.page_austin.learning_budget.title": 72,
      "bumblesite@team_bumble.page_austin.life_insurance.text": 73,
      "bumblesite@team_bumble.page_austin.life_insurance.title": 74,
      "bumblesite@team_bumble.page_austin.mental_health.text": 75,
      "bumblesite@team_bumble.page_austin.mental_health.title": 76,
      "bumblesite@team_bumble.page_austin.merch.text": 77,
      "bumblesite@team_bumble.page_austin.merch.title": 78,
      "bumblesite@team_bumble.page_austin.page_title": 79,
      "bumblesite@team_bumble.page_austin.paid_time.text": 80,
      "bumblesite@team_bumble.page_austin.paid_time.title": 81,
      "bumblesite@team_bumble.page_austin.parental_leave.text": 82,
      "bumblesite@team_bumble.page_austin.parental_leave.title": 83,
      "bumblesite@team_bumble.page_austin.premium_app.text": 84,
      "bumblesite@team_bumble.page_austin.premium_app.title": 85,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.text": 86,
      "bumblesite@team_bumble.page_austin.private_medical_insurance.title": 87,
      "bumblesite@team_bumble.page_austin.random_coffee.text": 88,
      "bumblesite@team_bumble.page_austin.random_coffee.title": 89,
      "bumblesite@team_bumble.page_austin.retirement_plan.text": 90,
      "bumblesite@team_bumble.page_austin.retirement_plan.title": 91,
      "bumblesite@team_bumble.page_austin.team_activities.text": 92,
      "bumblesite@team_bumble.page_austin.team_activities.title": 93,
      "bumblesite@team_bumble.page_austin.wellness_budget.text": 94,
      "bumblesite@team_bumble.page_austin.wellness_budget.title": 95,
      "bumblesite@team_bumble.page_barcelona.page.text": 96,
      "bumblesite@team_bumble.page_barcelona.page.title": 97,
      "bumblesite@team_bumble.page_benefits.card.austin.subtitle": 98,
      "bumblesite@team_bumble.page_benefits.card.austin.title": 99,
      "bumblesite@team_bumble.page_benefits.card.barcelona.coming_soon": 100,
      "bumblesite@team_bumble.page_benefits.card.barcelona.subtitle": 101,
      "bumblesite@team_bumble.page_benefits.card.barcelona.title": 102,
      "bumblesite@team_bumble.page_benefits.card.london.subtitle": 103,
      "bumblesite@team_bumble.page_benefits.card.london.title": 104,
      "bumblesite@team_bumble.page_benefits.card.moscow.subtitle": 105,
      "bumblesite@team_bumble.page_benefits.card.moscow.title": 106,
      "bumblesite@team_bumble.page_benefits.page.intro": 107,
      "bumblesite@team_bumble.page_benefits.page.title": 108,
      "bumblesite@team_bumble.page_careers.communications_block.text": 109,
      "bumblesite@team_bumble.page_careers.communications_block.title": 110,
      "bumblesite@team_bumble.page_careers.community_block.text": 111,
      "bumblesite@team_bumble.page_careers.community_block.title": 112,
      "bumblesite@team_bumble.page_careers.culture_block.text": 113,
      "bumblesite@team_bumble.page_careers.culture_block.title": 114,
      "bumblesite@team_bumble.page_careers.design_block.text": 115,
      "bumblesite@team_bumble.page_careers.design_block.title": 116,
      "bumblesite@team_bumble.page_careers.engineering_block.text": 117,
      "bumblesite@team_bumble.page_careers.engineering_block.title": 118,
      "bumblesite@team_bumble.page_careers.engineering_block.title.uppercase": 119,
      "bumblesite@team_bumble.page_careers.excellence_block.text": 120,
      "bumblesite@team_bumble.page_careers.excellence_block.title": 121,
      "bumblesite@team_bumble.page_careers.finance_block.text": 122,
      "bumblesite@team_bumble.page_careers.finance_block.title": 123,
      "bumblesite@team_bumble.page_careers.intelligence_block.text": 124,
      "bumblesite@team_bumble.page_careers.intelligence_block.title": 125,
      "bumblesite@team_bumble.page_careers.learn_more_cta": 126,
      "bumblesite@team_bumble.page_careers.legal_block.text": 127,
      "bumblesite@team_bumble.page_careers.legal_block.title": 128,
      "bumblesite@team_bumble.page_careers.marketing_block.text": 129,
      "bumblesite@team_bumble.page_careers.marketing_block.title": 130,
      "bumblesite@team_bumble.page_careers.marketing_block.title.uppercase": 131,
      "bumblesite@team_bumble.page_careers.page.header": 132,
      "bumblesite@team_bumble.page_careers.page.title": 133,
      "bumblesite@team_bumble.page_careers.product_block.text": 134,
      "bumblesite@team_bumble.page_careers.product_block.title": 135,
      "bumblesite@team_bumble.page_careers.reseach_block.text": 136,
      "bumblesite@team_bumble.page_careers.reseach_block.title": 137,
      "bumblesite@team_bumble.page_design.badoo_block.text": 138,
      "bumblesite@team_bumble.page_design.badoo_block.title": 139,
      "bumblesite@team_bumble.page_design.bumble_block.text": 140,
      "bumblesite@team_bumble.page_design.bumble_block.title": 141,
      "bumblesite@team_bumble.page_design.design_block.text": 142,
      "bumblesite@team_bumble.page_design.design_block.title": 143,
      "bumblesite@team_bumble.page_design.design_block.title.uppercase": 144,
      "bumblesite@team_bumble.page_engineering.good_block.text": 145,
      "bumblesite@team_bumble.page_engineering.good_block.title": 146,
      "bumblesite@team_bumble.page_engineering.page.header": 147,
      "bumblesite@team_bumble.page_engineering.page.intro": 148,
      "bumblesite@team_bumble.page_engineering.technology_block.text": 149,
      "bumblesite@team_bumble.page_engineering.technology_block.title": 150,
      "bumblesite@team_bumble.page_engineering.video_block.cta": 151,
      "bumblesite@team_bumble.page_engineering.video_block.text.first": 152,
      "bumblesite@team_bumble.page_engineering.video_block.text.second": 153,
      "bumblesite@team_bumble.page_engineering.video_block.title": 154,
      "bumblesite@team_bumble.page_index.ceo_message.text": 155,
      "bumblesite@team_bumble.page_index.ceo_message.title": 156,
      "bumblesite@team_bumble.page_index.cookie.banner.accept_all": 157,
      "bumblesite@team_bumble.page_index.cookie.banner.manage_options": 158,
      "bumblesite@team_bumble.page_index.cookie.banner.text": 159,
      "bumblesite@team_bumble.page_index.cookie.banner.title": 160,
      "bumblesite@team_bumble.page_index.footer.body": 161,
      "bumblesite@team_bumble.page_index.footer.links.cookie": 162,
      "bumblesite@team_bumble.page_index.footer.links.gender_gap": 163,
      "bumblesite@team_bumble.page_index.footer.links.privacy": 164,
      "bumblesite@team_bumble.page_index.footer.links.slavery": 165,
      "bumblesite@team_bumble.page_index.footer.links.terms": 166,
      "bumblesite@team_bumble.page_index.hero_block.title": 167,
      "bumblesite@team_bumble.page_index.navigation.links.about": 168,
      "bumblesite@team_bumble.page_index.navigation.links.benefits": 169,
      "bumblesite@team_bumble.page_index.navigation.links.life": 170,
      "bumblesite@team_bumble.page_index.navigation.links.teams": 171,
      "bumblesite@team_bumble.page_index.navigation.menu": 172,
      "bumblesite@team_bumble.page_index.navigation.menu.join_cta": 173,
      "bumblesite@team_bumble.page_index.navigation.open_roles": 174,
      "bumblesite@team_bumble.page_index.success_story.couple.names": 175,
      "bumblesite@team_bumble.page_index.success_story.text": 176,
      "bumblesite@team_bumble.page_index.success_story.title": 177,
      "bumblesite@team_bumble.page_life.life_block.header": 178,
      "bumblesite@team_bumble.page_life.life_block.text.first": 179,
      "bumblesite@team_bumble.page_life.life_block.text.second": 180,
      "bumblesite@team_bumble.page_life.life_block.title": 181,
      "bumblesite@team_bumble.page_life.moves_block.career.text": 182,
      "bumblesite@team_bumble.page_life.moves_block.career.title": 183,
      "bumblesite@team_bumble.page_life.moves_block.change.text": 184,
      "bumblesite@team_bumble.page_life.moves_block.change.title": 185,
      "bumblesite@team_bumble.page_life.moves_block.good.text": 186,
      "bumblesite@team_bumble.page_life.moves_block.good.title": 187,
      "bumblesite@team_bumble.page_life.moves_block.header": 188,
      "bumblesite@team_bumble.page_life.moves_block.title": 189,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.header": 190,
      "bumblesite@team_bumble.page_life.testimonials_badoo_block.title": 191,
      "bumblesite@team_bumble.page_life.testimonials_badoo_video_block.title": 192,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.caption": 193,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_first.location": 194,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.caption": 195,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_second.location": 196,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.caption": 197,
      "bumblesite@team_bumble.page_life.testimonials_block.employee_third.location": 198,
      "bumblesite@team_bumble.page_life.testimonials_block.header": 199,
      "bumblesite@team_bumble.page_life.testimonials_block.title": 200,
      "bumblesite@team_bumble.page_life.values_block.accountability.text": 201,
      "bumblesite@team_bumble.page_life.values_block.accountability.title": 202,
      "bumblesite@team_bumble.page_life.values_block.first_move.text": 203,
      "bumblesite@team_bumble.page_life.values_block.first_move.title": 204,
      "bumblesite@team_bumble.page_life.values_block.growth.text": 205,
      "bumblesite@team_bumble.page_life.values_block.growth.title": 206,
      "bumblesite@team_bumble.page_life.values_block.header": 207,
      "bumblesite@team_bumble.page_life.values_block.honesty.text": 208,
      "bumblesite@team_bumble.page_life.values_block.honesty.title": 209,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.text": 210,
      "bumblesite@team_bumble.page_life.values_block.inclusivity.title": 211,
      "bumblesite@team_bumble.page_life.values_block.kindness.text": 212,
      "bumblesite@team_bumble.page_life.values_block.kindness.title": 213,
      "bumblesite@team_bumble.page_london.annual_leave_block.text": 214,
      "bumblesite@team_bumble.page_london.annual_leave_block.title": 215,
      "bumblesite@team_bumble.page_london.babylon_app_block.text": 216,
      "bumblesite@team_bumble.page_london.babylon_app_block.title": 217,
      "bumblesite@team_bumble.page_london.conference_block.text.new": 218,
      "bumblesite@team_bumble.page_london.conference_block.title": 219,
      "bumblesite@team_bumble.page_london.cycle_block.text": 220,
      "bumblesite@team_bumble.page_london.cycle_block.title": 221,
      "bumblesite@team_bumble.page_london.dental_insurance.text": 222,
      "bumblesite@team_bumble.page_london.dental_insurance.title": 223,
      "bumblesite@team_bumble.page_london.double_pension_block.text": 224,
      "bumblesite@team_bumble.page_london.double_pension_block.title": 225,
      "bumblesite@team_bumble.page_london.eip.text": 226,
      "bumblesite@team_bumble.page_london.eip.title": 227,
      "bumblesite@team_bumble.page_london.food.text": 228,
      "bumblesite@team_bumble.page_london.gym_flex_block.text": 229,
      "bumblesite@team_bumble.page_london.gym_flex_block.title": 230,
      "bumblesite@team_bumble.page_london.income_protection_block.text": 231,
      "bumblesite@team_bumble.page_london.income_protection_block.title": 232,
      "bumblesite@team_bumble.page_london.mental_health_block.text": 233,
      "bumblesite@team_bumble.page_london.mental_health_block.title": 234,
      "bumblesite@team_bumble.page_london.page.title": 235,
      "bumblesite@team_bumble.page_london.parental_leave_block.text": 236,
      "bumblesite@team_bumble.page_london.parental_leave_block.title": 237,
      "bumblesite@team_bumble.page_london.payroll_block.text": 238,
      "bumblesite@team_bumble.page_london.payroll_block.title": 239,
      "bumblesite@team_bumble.page_london.private_medical_insurance.title": 240,
      "bumblesite@team_bumble.page_london.season_ticket_block.text": 241,
      "bumblesite@team_bumble.page_london.season_ticket_block.title": 242,
      "bumblesite@team_bumble.page_london.sports_block.text": 243,
      "bumblesite@team_bumble.page_london.sports_block.title": 244,
      "bumblesite@team_bumble.page_london.team_activities_block.text": 245,
      "bumblesite@team_bumble.page_london.team_activities_block.title": 246,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.text": 247,
      "bumblesite@team_bumble.page_london.vision_vouchers_block.title": 248,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.text": 249,
      "bumblesite@team_bumble.page_london.wellbeing_room_block.title": 250,
      "bumblesite@team_bumble.page_marketing.badoo_block.text": 251,
      "bumblesite@team_bumble.page_marketing.badoo_block.title": 252,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.first": 253,
      "bumblesite@team_bumble.page_marketing.bumble_block.text.second": 254,
      "bumblesite@team_bumble.page_marketing.bumble_block.title": 255,
      "bumblesite@team_bumble.page_moscow.accident_insurance.text": 256,
      "bumblesite@team_bumble.page_moscow.accident_insurance.title": 257,
      "bumblesite@team_bumble.page_moscow.annual_leave.text": 258,
      "bumblesite@team_bumble.page_moscow.annual_leave.title": 259,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.text.new": 260,
      "bumblesite@team_bumble.page_moscow.flexible_benefits.title": 261,
      "bumblesite@team_bumble.page_moscow.health_insurance.text": 262,
      "bumblesite@team_bumble.page_moscow.health_insurance.title": 263,
      "bumblesite@team_bumble.page_moscow.office_doctor.text": 264,
      "bumblesite@team_bumble.page_moscow.office_doctor.title": 265,
      "bumblesite@team_bumble.page_moscow.page.title": 266,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.text": 267,
      "bumblesite@team_bumble.page_moscow.pharmacy_progam.title": 268,
      "bumblesite@team_bumble.page_moscow.table_tennis.text": 269,
      "bumblesite@team_bumble.page_moscow.table_tennis.title": 270,
      "bumblesite@team_bumble.page_open_roles.apply_now.cta": 271,
      "bumblesite@team_bumble.page_open_roles.locations.dropdown.title": 272,
      "bumblesite@team_bumble.page_open_roles.page.title": 273,
      "bumblesite@team_bumble.page_open_roles.share_job.title": 274,
      "bumblesite@team_bumble.page_open_roles.sort_by.dropdown.title": 275,
      "bumblesite@team_bumble.page_open_roles.teams.dropdown.title": 276,
      "bumblesite@team_bumble.sharing_assets.bumble_homepage_tab.title": 277,
      "bumblesite@team_bumble.sharing_assets.bumble_life_tab.title": 278,
      "bumblesite@team_bumble.sharing_assets.hiring_block.text": 279,
      "bumblesite@team_bumble.sharing_assets.hiring_block.title": 280,
      "bma_client@cookie-notification.text": 281,
      "bma_client@dialog.photo_verification.not_access.download_app": 282,
      "bma_client@download_app_android": 283,
      "bma_client@download_app_ios": 284,
      "bma_client@pages.error.not-found.description": 285,
      "bma_client@pages.login-password.submit": 286,
      "bma_client@pages.registration.form.submit": 287,
      "bma_client@pages.registration.form.title": 288,
      "bma_client@video_chat.error_title": 289
} as const;
